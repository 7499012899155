var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"padding":"20px"}},[_c('h2',{attrs:{"align":"center"}},[_vm._v("Neue Tajneed")]),_c('v-form',{ref:"form"},[_c('span',[_vm._v("User: "+_vm._s(this.newTajneed.requesterUsername)+" ")]),_c('br'),_c('br'),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.digitsOnly ],"dense":"","label":"Jamaat-ID"},model:{value:(_vm.newTajneed.aimsId),callback:function ($$v) {_vm.$set(_vm.newTajneed, "aimsId", $$v)},expression:"newTajneed.aimsId"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.validWaqfenauId ],"dense":"","label":"Waqf-e-Nau-ID"},model:{value:(_vm.newTajneed.waqfenauId),callback:function ($$v) {_vm.$set(_vm.newTajneed, "waqfenauId", $$v)},expression:"newTajneed.waqfenauId"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.dateFormat ],"dense":"","label":"Geburtsdatum"},model:{value:(_vm.newTajneed.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.newTajneed, "dateOfBirth", $$v)},expression:"newTajneed.dateOfBirth"}}),(_vm.newTajneed.sex=='Männlich')?_c('v-autocomplete',{staticStyle:{"border-radius":"0px"},attrs:{"dense":"","outlined":"","items":_vm.jamaatItems,"rules":[
          _vm.rules.required ],"label":"Jamaat"},model:{value:(_vm.newTajneed.jamaat),callback:function ($$v) {_vm.$set(_vm.newTajneed, "jamaat", $$v)},expression:"newTajneed.jamaat"}}):_vm._e(),(_vm.newTajneed.sex=='Weiblich')?_c('v-autocomplete',{staticStyle:{"border-radius":"0px"},attrs:{"dense":"","outlined":"","items":_vm.majlisItems,"rules":[
          _vm.rules.required ],"label":"Majlis"},model:{value:(_vm.newTajneed.majlis),callback:function ($$v) {_vm.$set(_vm.newTajneed, "majlis", $$v)},expression:"newTajneed.majlis"}}):_vm._e()],1),_c('br'),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","justify":'center'}},[_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.approveNewTajneed($event)}}},[_vm._v("Genehmigen")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.declineNewTajneed($event)}}},[_vm._v("Ablehnen")])],1),(_vm.showOverlay)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }