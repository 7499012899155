




































































































































































































































































































































































































































































































































import moment from 'moment';
import Vue from 'vue';
import {Component, Watch} from "vue-property-decorator";
import {RegistrationI} from '../../../models/registration';
import RegistrationService from "../registration.service";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import RegistrationResultDialog from "../components/RegistrationResultDialog.vue";
import BasedataService from "../../../services/basedata.service"

@Component({
  components: {RegistrationResultDialog, LoadingOverlay},
})
export default class Registration extends Vue {

  private showOverlay = false;

  private registrationI: RegistrationI = {
    mainperson: {},
    partner: {},
    children: []
  };

  private jamaatItems: any[] = [];

  private majlisItems: any[] = [];

  private dateOfBirth = "";

  private registrationFailed = false;

  private registrationSuccess = false;

  private resultMessage: string[] = [];

  private complianceText = "";

  private emailConfirmation = "";

  private isOlderThenSixteen = false;

  private isPartnerWaqf = "";

  private dateOfBirthPartner = "";

  private dateOfBirthChildren: string[] = [];

  private emailConfirmationPartner = "";

  private e1 = 1;

  private amountWaqfChildren = "";

  private firstChildStep: number = 2;

  private formChild = "formChild";

  private registrationResultDialogVisible = false;

  private daterules = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein.',
    (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
  ];

  private daterulesforchildren = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein.',
    (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    (v: string) => this.checkIsOlderThenSixteen(v) || 'Die Person ist bereits 16 Jahre alt und sollte seinen eigenen Account haben.',

  ];

  private notempty = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein.',
  ];

  private onlydigitsorempty = [
    (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
  ];

  private notemptyAndNoSpecialChar = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein',
    (v: string) => /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z]$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer',
  ];

  private emailRules = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein',
    (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-Mail überprüfen',
  ]


  private emailConfirmationRules = [
    //(v: string) => !!v || 'Feld muss ausgefüllt sein',
    (v: string) => this.emailMatch(v) || 'Emails stimmen nicht überein',
  ]

  created() {

    BasedataService.getJamaatAndMajalis()
      .then((response: any) => {
        this.jamaatItems = response.data.jamaats;
        this.majlisItems = response.data.majalis;
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: 'Netzwerkfehler',
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });

    this.complianceText = this.complianceTextAdult;
  }

  private emailMatch(p: string) {
    if (this.emailConfirmation.length > 0) {
      //@ts-ignore
      return (this.registrationI.mainperson.email === this.emailConfirmation);
    }
    return true;
  }


  private registerUser() {

    let allValid = true;

    //We check validations here for the case the registration process
    //ended at that person (validateUser will not be called anymore for that case)
    if (!(this.$refs.formMain as Vue & { validate: () => boolean }).validate()) {
      allValid = false;
    }
    if (this.isPartnerWaqf == 'Ja') {
      if (!(this.$refs.formPartner as Vue & { validate: () => boolean }).validate()) {
        allValid = false;
      }
    }
    if (+this.amountWaqfChildren > 0) {
      if (+this.amountWaqfChildren == 1) {
        //@ts-ignore
        if (!(this.$refs.formChild0[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 2) {
        //@ts-ignore
        if (!(this.$refs.formChild1[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 3) {
        //@ts-ignore
        if (!(this.$refs.formChild2[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 4) {
        //@ts-ignore
        if (!(this.$refs.formChild3[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 5) {
        //@ts-ignore
        if (!(this.$refs.formChild4[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 6) {
        //@ts-ignore
        if (!(this.$refs.formChild5[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 7) {
        //@ts-ignore
        if (!(this.$refs.formChild6[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 8) {
        //@ts-ignore
        if (!(this.$refs.formChild7[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 9) {
        //@ts-ignore
        if (!(this.$refs.formChild8[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
      if (+this.amountWaqfChildren == 10) {
        //@ts-ignore
        if (!(this.$refs.formChild9[0] as Vue & { validate: () => boolean }).validate()) {
          allValid = false;
        }
      }
    }

    if (allValid) {
      // Save main person birth date
      //@ts-ignore
      let dateDateOfBirth = (moment.utc(this.dateOfBirth, "DD.MM.YYYY")).format('YYYY-MM-DD');
      // let dateDateOfBirth = this.dateOfBirth;
      //@ts-ignore
      this.registrationI.mainperson.dateOfBirth = dateDateOfBirth;

      // Save main person birth date
      if (this.isPartnerWaqf == 'Ja') {
        //@ts-ignore
        dateDateOfBirth = (moment.utc(this.dateOfBirthPartner, "DD.MM.YYYY")).format('YYYY-MM-DD');
        // dateDateOfBirth = this.dateOfBirthPartner;
        //@ts-ignore
        this.registrationI.partner.dateOfBirth = dateDateOfBirth;
      }

      if (+this.amountWaqfChildren > 0) {
        for (var i = 0; i < +this.amountWaqfChildren; i++) {
          //@ts-ignore
          dateDateOfBirth = (moment.utc(this.dateOfBirthChildren[i], "DD.MM.YYYY")).format('YYYY-MM-DD');
          ;
          // dateDateOfBirth = this.dateOfBirthChildren[i];
          //@ts-ignore
          this.registrationI.children[i].dateOfBirth = dateDateOfBirth;
        }
      }

      this.showOverlay = true;
      RegistrationService.register(this.registrationI)
        .then((response: any) => {
          this.showOverlay = false;
          this.registrationSuccess = true;
          this.registrationFailed = false;
          this.resultMessage = response.data;
          this.registrationResultDialogVisible = true;
          //this.resultMessage = response.data;
        })
        .catch((error: any) => {
          if (error.response.status == 500) {
            this.resultMessage = ["Es ist ein Fehler aufgetreten."];
          } else {
            this.resultMessage = error.response.data;
          }
          this.registrationFailed = true;
          this.registrationSuccess = false;
          this.showOverlay = false;
          this.registrationResultDialogVisible = true;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }
  }


  @Watch('amountWaqfChildren')
  onAmountWaqfChildrenChange(value: string, oldValue: string) {
    this.registrationI.children = [];
    this.dateOfBirthChildren = [];
    for (var i = 0; i < +value; i++) {
      this.registrationI.children.push({});
      this.dateOfBirthChildren[i] = "";
    }
  }

  @Watch('isPartnerWaqf')
  onIsPartnerWaqfChange(value: string, oldValue: string) {
    if (value == 'Ja') {
      this.firstChildStep = 3;
    } else {
      this.registrationI.partner = {};
      this.firstChildStep = 2;
    }
  }

  @Watch('dateOfBirth')
  onPropertyChanged1(value: string, oldValue: string) {
    //For new tajneed entry check for null, because field is empty:
    if (value != null) {
      if (value.match(/^([1-9]|0[1-9]|[12][0-9]|3[01])[-\.]([1-9]|0[1-9]|1[012])[-\.](19|20)\d\d$/)) {
        let dateString = value;
        let dateParts = dateString.split(".");
        // month is 0-based, that's why we need dataParts[1] - 1
        let dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

        let age = this.calculateAge(dateObject);

        if (age < 16) {
          this.isOlderThenSixteen = false;
          this.complianceText = this.complianceTextChild;
          this.isPartnerWaqf = 'Nein';
        } else {
          this.isOlderThenSixteen = true;
          this.complianceText = this.complianceTextAdult;
        }

      }
    }
  }

  calculateAge(birthday: Date) { // birthday is a date
    var ageDifMs = Date.now() - +birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }


  private validateStep(form: number) {
    if (form == 1) {
      if ((this.$refs.formMain as Vue & { validate: () => boolean }).validate()) {
        this.e1 = 2;
      }
    }
    if (form == 2) {
      if ((this.$refs.formPartner as Vue & { validate: () => boolean }).validate()) {
        this.e1 = 3;
      }
    }
  }


  private validateChildStep(childIndex: number) {
    if (childIndex == 0) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild0[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 1) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild1[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 2) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild2[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 3) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild3[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 4) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild4[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 5) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild5[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 6) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild6[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 6) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild6[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 7) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild7[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 8) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild8[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 9) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild9[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
    if (childIndex == 10) {
      //We have the array notation because somehow it is an array of forms, dont know why
      //@ts-ignore
      if ((this.$refs.formChild10[0] as Vue & { validate: () => boolean }).validate()) {
        this.e1 = +childIndex + this.firstChildStep + 1
      }
    }
  }

  private checkIsOlderThenSixteen(value: string) {

    let dateString = value;
    let dateParts = dateString.split(".");
    // month is 0-based, that's why we need dataParts[1] - 1
    let dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

    let age = this.calculateAge(dateObject);

    if (age >= 16) {
      return false;
    } else {
      return true;
    }
  }

  private complianceTextAdult = "Ich willige freiwillig und ausdrücklich ein, " +
    "dass die AMJ meine personenbezogenen Daten (bspw. Name, Adresse, Kontaktdaten, Geschlecht, " +
    "Waqf-e-Nau-, Mitglieds- & ID-Nummer, ggf. Wassiyat Nr., Religionsdaten, Nationalität, " +
    "Gesundheitsdaten, Bildungsdaten, ggf. Photographie) zum Zwecke der Verwaltung " +
    "und Organisation der besonderen 'Waqf-e-Nau' Mitgliedschaft unter Beachtung der " +
    "EU - DSGVO und BDSG verarbeitet. Empfänger dieser Daten sind im Rahmen gesetzlicher " +
    "Pflichten und Mitteilungsbefugnisse Dritte, wie die Sadr Anjuman Ahmadiyya (Rabwah, Pakistan) " +
    "und AMJ International (London, UK). ";

  private complianceTextChild = "Ich willige freiwillig und ausdrücklich ein, " +
    "dass die AMJ die personenbezogenen Daten von meinem Kind (bspw. Name, " +
    "Adresse, Kontaktdaten, Geschlecht, Waqf-e-Nau-, Mitglieds- & ID-Nummer, ggf. " +
    "Wassiyat Nr., Religionsdaten, Nationalität, Gesundheitsdaten, Bildungsdaten, ggf. Photographie) " +
    "zum Zwecke der Verwaltung und Organisation der besonderen 'Waqf-e-Nau' Mitgliedschaft " +
    "unter Beachtung der EU - DSGVO und BDSG verarbeitet. Empfänger dieser Daten sind im " +
    "Rahmen gesetzlicher Pflichten und Mitteilungsbefugnisse Dritte, wie die Sadr Anjuman Ahmadiyya (Rabwah, Pakistan) " +
    "und AMJ International (London, UK).";

  /* private complianceTextSecond = "Mein Einverständnis kann ich, ohne nachteilige Folgen für mich, verweigern bzw. jederzeit mit zukünftiger Wirkung widerrufen. " +
    " Mir ist bekannt, dass dies nicht die Rechtmäßigkeit der bisher auf der Grundlage der vorherigen Einwilligung erfolgten " +
    "Verarbeitung berührt. Meine Fragen, Anregungen und Rechtsgesuche, wie Löschung, Auskunft, Berichtigung oder Widerruf, bzgl. meiner Daten " +
    "kann ich postalisch an: Datenschutzbeauftragter der AMJ KdöR, Genferstraße 11 in 60437 Frankfurt am Main oder per Email-Nachricht an datenschutz@ahmadiyya.de " +
    "richten. Mehr Informationen zum Datenschutz finden Sie " ; */


  private complianceTextHeader = "Wir als Ahmadiyya Muslim Jamaat Deutschland KdöR (\"AMJ\") brauchen Ihre " +
    "datenschutzrechtliche Einwilligung (über dem 16. Lebensjahr vom Waqfeen, darunter von den Eltern oder Erziehungsberechtigten)" +
    "um Ihre personenbezogenen Daten für das Verwalten und Organisieren der Mitgliedschaft im \"Waqf-e-Nau\" Programms " +
    "verarbeiten zu dürfen (gemäß Artikel 4 Ziff.11, Art. 6 Abs. 1 lit. (a), Art. 7 und Art. 9 Abs. 2 lit. (a) DSGVO, sowie § 51 BDSG). ";

  // language=HTML
  private complianceTextFooter = "Mein Einverständnis kann ich, ohne nachteilige Folgen für mich, verweigern bzw. jederzeit mit zukünftiger Wirkung widerrufen. " +
    " Mir ist bekannt, dass dies nicht die Rechtmäßigkeit der bisher auf der Grundlage der vorherigen Einwilligung erfolgten " +
    "Verarbeitung berührt. Meine Fragen, Anregungen und Rechtsgesuche, wie Löschung, Auskunft, Berichtigung oder Widerruf, bzgl. meiner Daten " +
    "kann ich postalisch an: Datenschutzbeauftragter der AMJ KdöR, Genferstraße 11 in 60437 Frankfurt am Main oder per Email-Nachricht an datenschutz@ahmadiyya.de " +
    "richten. Mehr Informationen zum Datenschutz finden Sie <a target=\"_blank\" href=\"https://waqfenau.ahmadiyya.de/datenschutz\" > hier. </a> "


  maxwidth() {
    if (this.isPartnerWaqf == 'Ja' && +this.amountWaqfChildren > 7) {
      return 'xxlargewidth';
    }
    if (this.isPartnerWaqf == 'Ja' && +this.amountWaqfChildren > 3) {
      return 'xlargewidth';
    }
    if (this.isPartnerWaqf == 'Ja' && +this.amountWaqfChildren > 0) {
      return 'largewidth';
    }
    if (this.isPartnerWaqf == 'Ja') {
      return 'mediumwidth';
    }
    if (+this.amountWaqfChildren > 3) {
      return 'xlargewidth';
    }
    if (+this.amountWaqfChildren > 0) {
      return 'mediumwidth';
    }
    else {
      return 'normalwidth';
    }
  }

  private handlerCloseRegistrationResultDialog() {
    this.registrationResultDialogVisible = false;

  }
}

