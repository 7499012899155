




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue";
import {Getter} from "vuex-class";
import {OwnMetaData} from "../../tajneed/tajneed.types";
import {Exam} from "../../exam/exam.types";
import ExamService from "../../exam/exam.service";
import {AxiosResponse} from "axios";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {
    AppLoader,
    LoadingOverlay
  },
})
export default class ViewExam extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
  private getOwnMetaData!: OwnMetaData;

  private exam: Exam = {
    status: "CREATED",
    year: 0
  };

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Nur Zahlen erlaubt.',
    notMoreThenTwentyFour: (v: number) => v <= 24 || 'Nur maximal 24 erlaubt'
  };
  
  private showOverlay: boolean = false;
  private loadingReport: boolean = false;

  private finishDisabled: boolean = false;

  beforeCreate(){
    if (!this.$route.params.examId) {
      this.$router.push({path: '/'});
    }
  }

  created() {
    console.log(this.$route.params.examId);
    if(this.$route.params.examId) {
      this.loadExam();
    } 
  }

  loadExam() {
    this.loadingReport = true;
      //@ts-ignore
      ExamService.getIndividualExam(this.$route.params.examId, this.$route.params.personId).then((response: AxiosResponse<Exam>) => {
        this.exam = response.data;
        this.loadingReport = false;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReport = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReport = false;
        }, 5000);
      });
    } 

  private finishReport() {
    ExamService.finishExam(this.exam, this.$route.params.personId)
      .then((response: any) => {
        this.showOverlay = false;
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: response.data,
          type: 'success'
        });
        this.loadExam();
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error.response!.data.message,
          type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });          
  }

  private doSave(request: any) {
    ExamService.saveExam(this.exam, this.$route.params.personId)
      .then((response: any) => {
        this.showOverlay = false;
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: response.data,
          type: 'success'
        });
        this.loadExam();
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error.response!.data.message,
          type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }



  getTextForStatus(status: string): string {
    switch (status) {
      case "NEW" :
        return "NEU"
      case "CREATED" :
        return "ERSTELLT"
      case "SAVED" :
        return "ZWISCHENGESPEICHERT"
      case "FINISHED" :
        return "ABGESCHLOSSEN"
      case "EXPIRED" :
        return "ABGELAUFEN"
      default:
        return "";
    }
  }
}
