var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"padding":"20px"}},[(!_vm.selectedCase)?_c('h2',{attrs:{"align":"center"}},[_vm._v("Beratungsfall erstellen")]):(_vm.addingToTajneed)?_c('h2',{attrs:{"align":"center"}},[_vm._v("Zu Tajneed hinzufügen")]):_c('h2',{attrs:{"align":"center"}},[_vm._v("Beratungsfall bearbeiten")]),_c('br'),_c('v-container',[_c('v-form',{ref:"form"},[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required ],"dense":"","label":"PF-Nummer","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.pfNumber),callback:function ($$v) {_vm.$set(_vm.newCase, "pfNumber", $$v)},expression:"newCase.pfNumber"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required
        ],"dense":"","label":"Name Kind","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.childName),callback:function ($$v) {_vm.$set(_vm.newCase, "childName", $$v)},expression:"newCase.childName"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required ],"dense":"","label":"Name Vater","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.fathersName),callback:function ($$v) {_vm.$set(_vm.newCase, "fathersName", $$v)},expression:"newCase.fathersName"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required ],"dense":"","label":"Name Mutter","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.mothersName),callback:function ($$v) {_vm.$set(_vm.newCase, "mothersName", $$v)},expression:"newCase.mothersName"}}),_c('v-select',{staticStyle:{"border-radius":"0px"},attrs:{"items":['Männlich', 'Weiblich'],"outlined":"","dense":"","hide-selected":"","label":"Geschlecht","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.sex),callback:function ($$v) {_vm.$set(_vm.newCase, "sex", $$v)},expression:"newCase.sex"}}),(!_vm.newCase.sex || _vm.newCase.sex == 'Männlich')?_c('v-autocomplete',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","hide-selected":"","items":_vm.jamaatItems,"rules":[
          _vm.rules.required ],"dense":"","return-object":"","label":"Jamaat","disabled":_vm.addingToTajneed || _vm.sexUnknown},model:{value:(_vm.newCase.jamaatHalqa),callback:function ($$v) {_vm.$set(_vm.newCase, "jamaatHalqa", $$v)},expression:"newCase.jamaatHalqa"}}):_vm._e(),(_vm.newCase.sex == 'Weiblich')?_c('v-autocomplete',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","hide-selected":"","items":_vm.majlisItems,"rules":[
          _vm.rules.required ],"dense":"","return-object":"","label":"Majlis","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.lajnaMajlis),callback:function ($$v) {_vm.$set(_vm.newCase, "lajnaMajlis", $$v)},expression:"newCase.lajnaMajlis"}}):_vm._e(),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required ],"dense":"","label":"Adresse","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.address),callback:function ($$v) {_vm.$set(_vm.newCase, "address", $$v)},expression:"newCase.address"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required ],"dense":"","label":"Telefonnummer","disabled":_vm.addingToTajneed},model:{value:(_vm.newCase.phoneNumber),callback:function ($$v) {_vm.$set(_vm.newCase, "phoneNumber", $$v)},expression:"newCase.phoneNumber"}}),_c('div',{staticClass:"mb-3"},[_c('v-row',{staticClass:"mb-n5 normalrow"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.caseAttachmentDocuments!= null && _vm.caseAttachmentDocuments.length>0)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Dokumente: "),_c('br')]):_vm._e(),_vm._l((_vm.caseAttachmentDocuments),function(item){return _c('a',{key:item.id,staticStyle:{"color":"blue","cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.downloadAttachment(item.id, 'procurement_attachment')}}},[_vm._v(" "+_vm._s(item.fileName)+" "),_c('br')])})],2)],1)],1),(!_vm.addingToTajneed)?_c('v-file-input',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","dense":"","small-chips":"","multiple":"","label":"Dokumente","accept":".pdf"},model:{value:(_vm.caseAttachmentInput),callback:function ($$v) {_vm.caseAttachmentInput=$$v},expression:"caseAttachmentInput"}}):_vm._e(),(_vm.addingToTajneed)?_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"label":'Waqf-e-Nau Nummer',"outlined":"","dense":"","rules":_vm.notemptyAndNoSpecialChar},model:{value:(_vm.selectedCase.waqfenauId),callback:function ($$v) {_vm.$set(_vm.selectedCase, "waqfenauId", $$v)},expression:"selectedCase.waqfenauId"}}):_vm._e(),(_vm.addingToTajneed)?_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.dateFormat ],"dense":"","label":"Geburtsdatum"},model:{value:(_vm.selectedCase.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.selectedCase, "dateOfBirth", $$v)},expression:"selectedCase.dateOfBirth"}}):_vm._e()],1)],1),_c('br'),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{staticStyle:{"border-radius":"0px"},attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.createNewCaseEntry($event)}}},[(!_vm.selectedCase)?_c('span',[_vm._v("Beratungsfall erstellen")]):(_vm.addingToTajneed)?_c('span',[_vm._v("Zu Tajneed hinzufügen")]):_c('span',[_vm._v("Beratungsfall bearbeiten")])])],1),_c('br'),(_vm.showOverlay)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }